<template>
    <div class="the-footer flex flex-m">
        <div class="mr-5">Copyright© 2020-2021 佛山市佰方融通数据服务有限公司 All Rights Reserved |</div>
        <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备20050400号</a>
    </div>
</template>

<script>
    export default {
        components: {},
        props: {},
        data() {
            return {
            };
        },
        computed: {
        },
        watch: {},
        methods: {
        },
        created() {},
        mounted() {},
        destroyed() {}
    };
</script>

<style lang="scss" scoped>
    .the-footer {
        margin-bottom: 20px;
        padding: 0 20px;
        // border-top: 1px solid rgba($color: #fff, $alpha: 0.3);
        color: rgba($color: #fff, $alpha: 0.6);
        a {
           color: rgba($color: #fff, $alpha: 0.6); 
        }
    }
</style>
