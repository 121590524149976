<template>
    <div class="the-header flex flex-m flex-between">
        <div>
            <img src="@/assets/the-header-logo.png" />
        </div>
        <div class="msg">生态文明现代化建设服务商</div>
    </div>
</template>

<script>
    export default {
        components: {},
        props: {},
        data() {
            return {
            };
        },
        computed: {
        },
        watch: {},
        methods: {
        },
        created() {},
        mounted() {},
        destroyed() {}
    };
</script>

<style lang="scss" scoped>
    .the-header {
        height: 80px;
        flex: 0 0 80px;
        padding: 0 20px 0 0;
        border-bottom: 1px solid rgba($color: #fff, $alpha: 0.3);
        color: #fff;
    }
</style>
